import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { Container, Section, Main, Button, Logo } from "../components/Lib"

const NotFoundPage = ({ data }) => (
  <Container>
    <SEO title="404: صفحه یافت نشد!" />
    <Main>
      <Section className="not_found inner_wrapper">
        <Logo />
        <div className="not_found__number">404</div>
        <h1 className="not_found__title">صفحه موردنظر یافت نشد.</h1>
        <Button Tag="Link" to="/" iconName="home" color="primary-outlined">
          برگشت به خانه
        </Button>
        <section className="not_found__links">
          <div className="not_found__links__box">
            <h3>آخرین اپیزود‌ها</h3>
            <ul>
              {data.episodes.edges.map(({ node: episode }) => {
                return (
                  <Link
                    to={`/episode/${episode.fields.slug}`}
                    key={episode.fields.slug}
                    className="link"
                  >
                    <li>{episode.title}</li>
                  </Link>
                )
              })}
            </ul>
          </div>
          <div className="not_found__links__box">
            <h3>آخرین نوشته‌ها</h3>
            <ul>
              {data.posts.edges.map(({ node: post }) => {
                return (
                  <Link
                    to={`/blog/${post.fields.slug}`}
                    key={post.fields.slug}
                    className="link"
                  >
                    <li>{post.title}</li>
                  </Link>
                )
              })}
            </ul>
          </div>
        </section>
      </Section>
    </Main>
  </Container>
)

export const query = graphql`
  query {
    posts: allStrapiPost(limit: 3, sort: { fields: created_at, order: DESC }) {
      edges {
        node {
          title
          fields {
            slug
          }
        }
      }
    }
    episodes: allStrapiEpisode(
      limit: 3
      sort: { fields: episodeNumber, order: DESC }
    ) {
      edges {
        node {
          title
          fields {
            slug
          }
        }
      }
    }
  }
`

export default NotFoundPage
